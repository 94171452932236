import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/components/Navigation.scss';

const Navigation = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className='navigation'>
      <img className='toggle' src="./assets/navigation.png" alt="nagivation menu" onClick={() => setOpenMenu(!openMenu)} />
      <div className='menu' style={{ left: `${openMenu ? '0' : '-200'}px` }}>
        <button className={location.pathname === '/' ? 'chosen' : ''} onClick={() => navigate('/')}>Tracker</button>
        <button className={location.pathname === '/clicktest' ? 'chosen' : ''} onClick={() => navigate('/clicktest')}>CPS Test</button>
      </div>
    </div>
  );
};

export default Navigation;