import React from 'react';
import '../../styles/components/Team.scss'

const Team = ({ team, participants, Items }) => {

    const Participant = (p) => {
        return (
            <div className='card'>
                <div className='player'>
                    <img className='champion' src={p.champion.avatar} alt={p.champion.name} title={p.champion.name} />
                    <div className='summoner'>
                        <img src={p.summoner1.avatar} alt={p.summoner1.name} title={p.summoner1.name} />
                        <img src={p.summoner2.avatar} alt={p.summoner2.name} title={p.summoner2.name} />
                    </div>
                    <div className='name'>
                        <p>{p.name}</p>
                        <p style={{ color: '#9a96aa' }}>{p.goldEarned} PO</p>
                    </div>
                </div>

                <div className='kda'>
                    <p>{`${p.kills}/${p.deaths}/${p.assists}`}</p>
                    <p>{`${Math.round((p.kills + p.assists) / team.kills * 100)}% KP`}</p>
                </div>

                <div className='damage'>
                    <p>{p.damage}</p>
                </div>

                <div className='wards'>
                    <p>{p.vision}</p>
                </div>

                <div className='cs'>
                    <p>{p.cs}</p>
                </div>

                <div className='items'>
                    {Items(p.items, p.win)}
                </div>
            </div>
        )
    }

    return (
        <div className='team'>
            <div className='title'>
                <p>{`${team.win ? 'Victoire' : 'Défaite'} (Team ${team.id === 100 ? 'Rouge' : 'Bleue'})`}</p>
                <p>KDA</p>
                <p>Dégâts</p>
                <p>Wards</p>
                <p>CS</p>
                <p>Items</p>
            </div>

            <div className='body' style={{ backgroundColor: `${team.win ? '#28344e' : '#59343b'}` }}>
                {participants
                    .map((p) => (
                        <Participant key={p.name} {...p} />
                    ))
                }
            </div>
        </div>
    );
};

export default Team;