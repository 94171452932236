import React, { useState } from 'react';
import '../../styles/components/Game.scss';
import { getDate, getDateAgo, getFormattedTime } from '../../Utils';
import Statistics from './Statistics';
import Summary from './Summary';
import Team from './Team';

const Game = ({ tracker }) => {

  const [openDetails, setOpenDetails] = useState(false);
  const [detailsPage, setDetailsPage] = useState("summary");

  const name = tracker.name;
  const summonerId = tracker.summonerId;

  const player = tracker.participants.filter((p) => p.summonerId === summonerId)[0];
  const team = tracker.teams.filter((t) => t.id === player.teamId)[0];
  const opposite = tracker.teams.filter((t) => t.id !== player.teamId)[0];

  const participants1 = tracker.participants.filter((p) => p.teamId === tracker.teams[0].id);
  const participants2 = tracker.participants.filter((p) => p.teamId === tracker.teams[1].id);

  const Participants = (participants) => {
    return participants
      .map((p) => (
        <div key={p.name} className='participant'>
          <img src={p.champion.avatar} alt={p.champion.name} title={p.champion.name} />
          <p title={p.name} style={{ color: `${p.summonerId === player.summonerId ? "white" : "#9a96aa"}` }}>{p.name.length > 9 ? `${p.name.slice(0, 6)}...` : p.name}</p>
        </div>
      ));
  }

  const Items = (items, hasWin) => {
    items = items.filter((item) => item !== 0);

    const elements = [];
    for (let i = 0; i < 7; i++) {
      if (i + 1 >= items.length) {
        if (i !== 6) {
          elements.push(<div className="blank-item" key={i} style={{ backgroundColor: `${hasWin ? "#2f436e" : "#703c47"}` }}></div>);
        } else {
          const item = items[items.length - 1];
          elements.push(<img key={i} src={item.avatar} alt={item.name} title={`${item.name}\n${item.description}`} />);
        }

      } else {
        const item = items[i];
        elements.push(<img key={i} src={item.avatar} alt={item.name} title={`${item.name}\n${item.description}`} />);
      }
    }

    return elements;
  }

  return (
    <div className='tracker'>
      <div className='content' style={{ backgroundColor: `${player.win ? '#28344e' : '#59343b'}`, borderColor: `${player.win ? '#5383e8' : '#e84057'}` }}>
        <div className='game'>
          <p className='queue'>{tracker.metadata.queue}</p>
          <p className='date' title={getDate(tracker.metadata.end)}>{getDateAgo(tracker.metadata.end)}</p>
          <p className='result'>{player.win ? 'Victoire' : 'Défaite'}</p>
          <p className='length'>{getFormattedTime(tracker.metadata.duration)}</p>
        </div>

        <div className='infos'>
          <div className='upper'>
            <img className="champion" src={player.champion.avatar} alt={player.champion.name} title={player.champion.name} />
            <div className='summoner'>
              <img src={player.summoner1.avatar} alt={player.summoner1.name} title={`${player.summoner1.name}\n${player.summoner1.description}`} />
              <img src={player.summoner2.avatar} alt={player.summoner2.name} title={`${player.summoner2.name}\n${player.summoner2.description}`} />
            </div>
            <div className='kda'>
              <p title={tracker.name}>{tracker.name}</p>
              <p>{`${player.kills}/${player.deaths}/${player.assists}`}</p>
            </div>
          </div>

          <div className='items'>
            {Items(player.items, player.win)}
          </div>
        </div>

        <div className='stats'>
          <p>{`KP: ${Math.round((player.kills + player.assists) / team.kills * 100)}%`}</p>
          <p className='cs'>{`CS: ${player.cs} (${(player.cs / tracker.metadata.duration * 60).toFixed(1)})`}</p>
          {tracker.rank &&
            <>
              <br></br>
              <p>{`${tracker.rank.tier} ${tracker.rank.rank}`}</p>
              <p>{`${tracker.rank.current} (${tracker.rank.gain > 0 ? '+' : ''}${tracker.rank.gain})`}</p>
            </>
          }

        </div>

        <div className='participants'>
          <div>{Participants(participants1)}</div>
          <div>{Participants(participants2)}</div>
        </div>

        <div className={`details ${player.win ? 'win' : ''}`} onClick={() => setOpenDetails(!openDetails)}>
          <img className='game-icon' src={`./assets/LOL.webp`} alt='League of Legends' />
          <img className='arrow' src={`./assets/${openDetails ? 'arrow-up' : 'arrow-down'}.png`} alt="arrow down" />
        </div>
      </div>
      {openDetails &&
        <div className='details-container'>
          <div className='options'>
            <button className={detailsPage === 'summary' ? 'chosen' : ''} onClick={() => setDetailsPage("summary")}>Résumé</button>
            <button className={detailsPage === 'stats' ? 'chosen' : ''} onClick={() => setDetailsPage("stats")}>Statistiques</button>
          </div>
          {detailsPage === "summary" ?
            <>
              <Team team={team} participants={team.id === 100 ? participants1 : participants2} Items={Items} />
              <Summary participants={tracker.participants} team={team} opposite={opposite} />
              <Team team={opposite} participants={opposite.id === 100 ? participants1 : participants2} Items={Items} />
            </>

            :

            <>
              <Statistics name={name} p1={team.id === 100 ? participants1 : participants2} p2={team.id !== 100 ? participants1 : participants2} />
            </>
          }
        </div>
      }
    </div>
  );
};

export default Game;