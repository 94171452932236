import React from 'react';
import '../../styles/components/VTeam.scss'

const VTeam = ({ player, team, players }) => {

    const draw = team.wins === team.losses;

    const Player = (p) => {
        const stats = p.statistics;
        return (
            <div className='card' style={{ background: `${player.puuid === p.puuid ? `linear-gradient(to right, #efbf6c85, ${draw ? '#4f4f4f' : team.win ? '#28344e' : '#59343b'})` : ''}` }}>
                <div className='player'>
                    <img className='agent' src={p.character.avatar} alt={p.character.name} title={p.character.name} />
                    <img className='rank' src={p.rank.largeIconUrl} alt={p.rank.name} title={p.rank.name} />
                    <p className='name' title={p.character.name}>{p.name}</p>
                </div>

                <div className='score'>
                    <p>{stats.score}</p>
                </div>

                <div className='kda'>
                    <p>{stats.kills}/{stats.deaths}/{stats.assists}</p>
                </div>

                <div className='hs'>
                    <p>{(stats.headShots / (stats.bodyShots + stats.headShots + stats.legsShots) * 100).toFixed(1)}%</p>
                </div>

                <div className='fb'>
                    <p>{p.firstbloods}</p>
                </div>

                <div className='plants'>
                    <p>{p.plants}</p>
                </div>

                <div className='desamo'>
                    <p>{p.defuses}</p>
                </div>
            </div>
        )
    }

    return (
        <div className='valo-team'>
            <div className='title'>
                <p>{`${draw ? 'Égalité' : team.win ? 'Victoire' : 'Défaite'} (Team ${team.name.replace('red', 'Rouge').replace('blue', 'Bleue')})`}</p>
                <p>Score</p>
                <p>KDA</p>
                <p>HS%</p>
                <p title='Premiers Sangs'>PS</p>
                <p>Plants</p>
                <p title='Désamorçage'>Désamo.</p>
            </div>

            <div className='body' style={{ backgroundColor: `${draw ? '#4f4f4f' : team.win ? '#28344e' : '#59343b'}` }}>
                {players
                    .sort((a, b) => b.statistics.score - a.statistics.score)
                    .map((p) => (
                        <Player key={p.name} {...p} />
                    ))
                }
            </div>
        </div>
    );
};

export default VTeam;