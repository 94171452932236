import React, { useEffect, useState } from 'react';
import Game from '../components/lol/Game';
import VGame from '../components/valorant/VGame';
import "../styles/pages/Tracker.scss";

const Tracker = () => {

  const [trackers, setTrackers] = useState([]);

  useEffect(() => {

    fetch('https://api2.asakicorp.com/tracker')
      .then(response => response.json())
      .then(data => {
        setTrackers(data);
      })
  }, []);

  return (
    <div className='container'>
      <div className='game-list'>
        {trackers.sort((a, b) => b.metadata.end - a.metadata.end)
          .map((tracker, index) => (
            <li key={index}>
              {tracker.metadata.game === 'lol' ?
                <Game tracker={tracker} />
                :
                <VGame tracker={tracker} />
              }
            </li>
          ))
        }
      </div>
    </div>
  );
};

export default Tracker;