import React, { useState } from 'react';
import '../styles/pages/Click.scss'

const Click = () => {

    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);

    const [timer, setTimer] = useState(0);
    const [clicks, setClicks] = useState(0);

    function onClick() {
        if (!started && !finished) {
            setStarted(true);
            const interval = setInterval(() => {
                setTimer(t => {
                    if (t >= 10) {
                        setFinished(true);
                        clearInterval(interval);
                        return t
                    }

                    return parseFloat((t + 0.01).toFixed(2));
                });

            }, 10);
        }

        setClicks(clicks + 1);
    }

    return (
        <div>
            <div className='container'>
                {finished ?
                    <>
                        <p>Résultat: {(clicks / 100 * 10).toFixed(1)} CPS</p>
                        <button onClick={() => {
                            setStarted(false);
                            setFinished(false);
                            setTimer(0);
                            setClicks(0);
                        }}>Recommencer</button>
                    </>
                    :
                    <>
                        <h2>{clicks}</h2>
                        <div className='click' onClick={() => onClick()}>
                            <p>Clique ici</p>
                        </div>
                        <p>{timer.toFixed(2)}</p>
                    </>
                }
            </div>
        </div>
    );
};

export default Click;