import React, { useState } from 'react';
import '../../styles/components/Statistics.scss';

const Statistics = ({ name, p1, p2 }) => {

  const [hover, setHover] = useState(false);

  let maxDamage = 0;
  p1.concat(p2).forEach((p) => {
    if (p.damage > maxDamage) maxDamage = p.damage;
  });

  const Player = (p) => {
    const percentage = p.damage / maxDamage * 100;
    return (
      <div key={p.name} className='player'>
        <img style={{ border: `2px solid ${p.name === name ? '#fabe0a' : p.win ? '#0a96aa' : '#b71d36'}` }} src={p.champion.avatar} alt={p.champion.name} title={p.name} />
        <div className='graph' >
          <div className='bar' style={{ width: `${percentage / 100 * 600}px`, backgroundColor: p.win ? '#1ba9bd' : '#ec2040' }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          </div>

          {hover && (
            <div className='damage-container'>
              <p className='damage'>{p.damage}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='statistics'>
      {p1
        .sort((a, b) => (b.name === name) - (a.name === name))
        .map((p) => (Player(p)))}
      <span className='spacer'></span>
      {p2.map((p) => (Player(p)))}
    </div>
  );
};

export default Statistics;