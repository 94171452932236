import React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Click from "./pages/Click";
import Tracker from "./pages/Tracker";

import Navigation from "./components/Navigation";
import "./styles/App.scss";

function App() {
  console.log("coucou petit curieux");

  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="*" element={<Tracker />}></Route>
          <Route path="/clicktest" element={<Click />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;