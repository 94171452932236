import moment from 'moment/moment';
import 'moment/locale/fr'

export function getDateAgo(date) {
    const inputDate = moment(date);
    const currentDate = moment();

    const duration = moment.duration(currentDate.diff(inputDate));

    if (duration.asSeconds() < 60) {
        return "Il y a moins d'une minute";
    } else if (duration.asMinutes() < 60) {
        return `Il y a ${Math.floor(duration.asMinutes())} minute${Math.floor(duration.asMinutes()) > 1 ? "s" : ""}`;
    } else if (duration.asHours() < 24) {
        return `Il y a ${Math.floor(duration.asHours())} heure${Math.floor(duration.asHours()) > 1 ? "s" : ""}`;
    } else if (duration.asDays() < 7) {
        return `Il y a ${Math.floor(duration.asDays())} jour${Math.floor(duration.asDays()) > 1 ? "s" : ""}`;
    } else if (duration.asWeeks() < 4) {
        return `Il y a ${Math.floor(duration.asWeeks())} semaine${Math.floor(duration.asWeeks()) > 1 ? "s" : ""}`;
    } else if (duration.asMonths() < 12) {
        return `Il y a ${Math.floor(duration.asMonths())} mois`;
    } else {
        return `Il y a ${Math.floor(duration.asYears())} an${Math.floor(duration.asYears()) > 1 ? "s" : ""}`;
    }
}

export function getDate(date) {
    const inputDate = moment(date).locale('fr');
    return inputDate.format("ddd D MMM YYYY [à] HH[h]mm").toUpperCase();
}

export function getFormattedTime(timeInSeconds) {
    var sec = timeInSeconds % 60;
    var min = Math.floor(timeInSeconds / 60);
    var minute = min.toString().length <= 1 ? "0" + min : "" + min;
    var second = sec.toString().length <= 1 ? "0" + sec : "" + sec;
    return minute + "m" + second;
}