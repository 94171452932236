import React, { useState } from 'react';
import { getDate, getDateAgo, getFormattedTime } from '../../Utils';
import '../../styles/components/VGame.scss';
import VSummary from './VSummary';
import VTeam from './VTeam';

const VGame = ({ tracker }) => {

  const [openDetails, setOpenDetails] = useState(false);

  const name = tracker.name;
  const puuid = tracker.puuid;

  const player = tracker.players.find((p) => p.puuid === puuid);
  const stats = player.statistics;

  const players1 = tracker.players.filter((p) => p.team.toLowerCase() === tracker.teams[0].name.toLowerCase());
  const players2 = tracker.players.filter((p) => p.team.toLowerCase() === tracker.teams[1].name.toLowerCase());

  const team = tracker.teams.find((t) => t.name.toLowerCase() === player.team.toLowerCase());
  if (team === undefined) {
    console.log(player.team);
    return null;
  }
  const opposite = tracker.teams.find((t) => t.name !== player.team);

  const score = team.wins + "/" + team.losses;

  const Players = (players) => {
    return players
      .map((p) => (
        <div key={p.name} className='participant'>
          <img src={p.character.avatar} alt={p.character.name} title={p.character.name} />
          <p title={p.name} style={{ color: `${p.puuid === player.puuid ? "white" : "#9a96aa"}` }}>{p.name.length > 9 ? `${p.name.slice(0, 6)}...` : p.name}</p>
        </div>
      ));
  }

  return (
    <div className='tracker'>
      <div className='content' style={{
        backgroundColor: `${tracker.draw ? '#4f4f4f' : player.win ? '#28344e' : '#59343b'}`,
        borderColor: `${tracker.draw ? 'lightgray' : player.win ? '#5383e8' : '#e84057'}`
      }}>
        <div className='game'>
          <p className='map'>{tracker.metadata.map}</p>
          <p className='valo-date' title={getDate(tracker.metadata.end)}>{getDateAgo(tracker.metadata.end)}</p>
          <p className='result'>{tracker.draw ? 'Égalité' : player.win ? `Victoire` : `Défaite`}</p>
          <p className='score'>{score}</p>
          <p className='length'>{getFormattedTime(tracker.metadata.duration)}</p>
        </div>

        <div className='valo-infos'>
          <img src={player.character.avatar} alt={player.character.name} title={player.character.name} />
          <div className='kda'>
            <p>{name} </p>
            <p>{stats.kills}/{stats.deaths}/{stats.assists}</p>
          </div>
        </div>

        <div className='valo-stats'>
          <p>{`Score: ${stats.score}`}</p>
          <p className='hs'>{`HS%: ${(stats.headShots / (stats.bodyShots + stats.headShots + stats.legsShots) * 100).toFixed(1)}%`}</p>
          <p>{`${tracker.rank.tier.toUpperCase()}`}</p>
          <p>{`${tracker.rank.current} (${tracker.rank.gain > 0 ? '+' : ''}${tracker.rank.gain})`}</p>
        </div>


        <div className='participants'>
          <div>{Players(players1)}</div>
          <div>{Players(players2)}</div>
        </div>

        <div className={`details ${tracker.draw ? 'draw' : player.win ? 'win' : ''}`} onClick={() => setOpenDetails(!openDetails)}>
          <img className='game-icon' src={`./assets/Valorant.webp`} alt='Valorant' />
          <img className='arrow' src={`./assets/${openDetails ? 'arrow-up' : 'arrow-down'}.png`} alt="arrow down" />
        </div>
      </div>

      {openDetails &&
        <div className='details-vcontainer'>
          <VTeam player={player} team={team} players={team.name.toLowerCase() === players1[0].team.toLowerCase() ? players1 : players2} />
          <VSummary rounds={tracker.rounds} team={team} opposite={opposite} />
          <VTeam player={player} team={opposite} players={opposite.name.toLowerCase() === players1[0].team.toLowerCase() ? players1 : players2} />
        </div>
      }
    </div>
  );
};

export default VGame;