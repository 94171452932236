import React from 'react';
import '../../styles/components/VSummary.scss'

const VSummary = ({ rounds, team, opposite }) => {

    return (
        <div className='valo-summary'>
            <div className='team'>
                <p>{`Team ${team.name.toLowerCase() === 'blue' ? 'Bleue' : 'Rouge'}`}</p>
                <p style={{ color: '#58ddc4', fontWeight: '900' }}>{team.wins}</p>
            </div>

            <div className='rounds'>
                {rounds
                    .map((round, index) => {
                        const t = round.winningTeam.toLowerCase() === team.name.toLowerCase() ? team : opposite;
                        const prefix = round.endType === 'Bomb detonated' ? 'explosion' : round.endType === 'Bomb defused' ? 'diffuse' : round.endType === 'Eliminated' ? 'elimination' : 'time';
                        const suffix = t === team ? 'win' : 'loss';

                        const title = prefix === 'explosion' ? 'Explosion du spike' : prefix === 'diffuse' ? 'Désamorçage du spike' : prefix === 'elimination' ? 'Elimination des joueurs' : 'Temps imparti dépassé';
                        return (
                            <div className='round' key={index}>
                                <img src={`./assets/valorant/${prefix}${suffix}.webp`} alt={title} title={title} />
                                <p>{index + 1}</p>
                            </div>
                        )
                    })
                }
            </div>

            <div className='team'>
                <p>{`Team ${opposite.name.toLowerCase() === 'blue' ? 'Bleue' : 'Rouge'}`}</p>
                <p style={{ color: '#f05b57', fontWeight: '900' }}>{opposite.wins}</p>
            </div>
        </div>
    );
};

export default VSummary;