import React from 'react';
import '../../styles/components/Summary.scss'

const Summary = ({ participants, team, opposite }) => {

    const kills = team.kills + opposite.kills;
    const teamKill = team.kills / kills * 100;
    const oppositeKill = opposite.kills / kills * 100;

    let teamGold = 0, oppositeGold = 0, gold = 0;
    participants.forEach((p) => {
        if (p.teamId === team.id) {
            teamGold += p.goldEarned;
        } else {
            oppositeGold += p.goldEarned;
        }
    });

    gold = teamGold + oppositeGold;

    return (
        <div className='summary'>
            <div className='team'>
                <p>{`Baron: ${team.barons}`}</p>
                <p>{`Dragon: ${team.dragons}`}</p>
                <p>{`Tour: ${team.towers}`}</p>
            </div>

            <div className='graphs'>
                <div className='graph kill'>
                    <p className='title'>Total Kill</p>
                    <p className='data-left'>{team.kills}</p>
                    <p className='data-right'>{opposite.kills}</p>
                    <div style={{ flex: `1 1 ${teamKill}%`, backgroundColor: `${team.win ? '#5383e8' : '#e84057'}` }}></div>
                    <div style={{ flex: `1 1 ${oppositeKill}%`, backgroundColor: `${opposite.win ? '#5383e8' : '#e84057'}` }}></div>
                </div>
                <div className='graph gold'>
                    <p className='title'>Total Gold</p>
                    <p className='data-left'>{teamGold}</p>
                    <p className='data-right'>{oppositeGold}</p>
                    <div style={{ flex: `1 1 ${teamGold / gold * 100}%`, backgroundColor: `${team.win ? '#5383e8' : '#e84057'}` }}></div>
                    <div style={{ flex: `1 1 ${oppositeGold / gold * 100}%`, backgroundColor: `${opposite.win ? '#5383e8' : '#e84057'}` }}></div>
                </div>
            </div>

            <div className='team'>
                <p>{`Baron: ${opposite.barons}`}</p>
                <p>{`Dragon: ${opposite.dragons}`}</p>
                <p>{`Tour: ${opposite.towers}`}</p>
            </div>
        </div>
    );
};

export default Summary;